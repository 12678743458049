import React from "react";

function Search(props) {
    return (
        <div>
            <h2 className="text-[30px] text-center font-bold">Search Page</h2>
        </div>
    );
}

export default Search;
